function App() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-royal-light to-white">
      <div className="max-w-6xl mx-auto px-4 py-16">
        <img
          src={require("./assets/images/TrembathAppsLogo.png")}
          alt="Trembath Apps Logo"
          className="h-32 mx-auto mb-6"
        />
        <h1 className="text-5xl font-bold text-royal mb-4 text-center font-code">
          Trembath Apps
        </h1>

        <p className="text-xl text-royal/80 mb-12 text-center font-code">
          Creating simple, powerful apps that make a difference in people's
          lives.
        </p>

        <div className="bg-white rounded-lg shadow-lg p-6 max-w-2xl mx-auto">
          <h2 className="text-3xl font-semibold text-royal mb-1 font-code">
            Our Apps
          </h2>

          <a
            href="https://flexerworkout.app"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-4 hover:bg-royal-light p-4 rounded-lg transition duration-200"
          >
            <img
              src={require("./assets/images/FlexerLogoRounded.png")}
              alt="Flexer Logo"
              className="w-20 h-20 object-contain"
            />
            <div>
              <h3 className="text-3xl font-bold text-flexerRed font-inter">
                Flexer
              </h3>
              <p className="text-lg text-flexerDarkGray font-inter font-medium">
                Your intelligent workout companion.
              </p>
            </div>
          </a>
        </div>

        <div className="flex items-center justify-center mt-10 group relative">
          <a
            href="mailto:dillontrembath@trembathapps.com"
            className="flex items-center space-x-2 hover:opacity-80 transition duration-200"
          >
            <img
              src={require("./assets/images/EmailIconBlue.png")}
              alt="Email Icon"
              className="w-6 h-6 object-contain"
            />
            <span className="text-lg text-royal font-medium font-inter">
              Contact Us
            </span>
          </a>
          <div className="absolute -top-8 scale-0 group-hover:scale-100 transition-transform duration-200 bg-royal text-white px-2 py-1 rounded text-sm">
            dillontrembath@trembathapps.com
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
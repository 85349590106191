import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBf2qfTMWw1TzrXrp5nVh3NDsBsNhS58tA",
  authDomain: "trembathapps.firebaseapp.com",
  projectId: "trembathapps",
  storageBucket: "trembathapps.firebasestorage.app",
  messagingSenderId: "13599171522",
  appId: "1:13599171522:web:5dad2a09b4459d97ecec5b",
  measurementId: "G-KQTYSNN2BF"
};

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);